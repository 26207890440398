.skill {
  margin-bottom: 35px;
  position: relative;
  overflow: hidden;

  > p {
    margin: 0;
    font-size: 18px;
  }
}

.skill-bar {
  width: 100%;
  height: 5px;
  background: $primary;
  display: block;
  position: relative;

  span {
    position: absolute;
    border-top: 5px solid inherit;
    top: -30px;
    padding: 0;
    font-size: 18px;
    padding: 3px 0;
    font-weight: 500;
    right: 0;
  }
}

.skill-bar.skill-bar-secondary {
  // background: $secondary;
}

.skill-bar.skill-bar-success {
  background: $success;
}

.skill-bar.skill-bar-info {
  background: $info;
}

.skill-bar.skill-bar-warning {
  background: $warning;
}

.skill-bar.skill-bar-danger {
  background: $danger;
}

.skill-bar.skill-bar-light {
  background: $light;
}

.skill-bar.skill-bar-dark {
  background: $dark;
}

@for $i from 0 through 100 {
  .skill-#{$i} {
    width: percentage($i/100);
  }
}
