html {
  background-color: #faf9f6;
}
body {
  background-color: #faf9f6;
  background-image: url(/uploads/background.svg);
  background-attachment: fixed;
  background-size: cover;
}

.navbar {
  background-color: transparent;
}

u {
  text-decoration: none;
  background-image: linear-gradient(
    0deg,
    $color4 0,
    $color4 30%,
    transparent 40%,
    transparent
  );

  &:hover {
    background-image: linear-gradient(
      0deg,
      $color5 0,
      $color5 30%,
      transparent 0,
      transparent
    );
  }
}

blockquote {
  background: #f9f9f9;
  border-left: 10px solid $color3;
  margin: 1.5em 0px;
  padding: 0.5em 10px;
  // quotes: "\201C""\201D""\2018""\2019";

  &:before {
    content: open-quote;
    color: $color3;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
  }

  &:after {
    content: close-quote;
    color: transparent;
    font-size: 4em;
    line-height: 0.1em;
    vertical-align: -0.4em;
  }
  p {
    display: inline;
  }
}


.loader_spinner {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid $primary;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader_center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.interest_page {
  visibility: hidden;
}
