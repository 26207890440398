@import "../utilities/mixins";

$level-item-spacing: $block-spacing * 0.5 !default;

.level {
  @extend %block;

  align-items: center;
  justify-content: space-between;

  code {
    border-radius: $radius;
  }

  img {
    display: inline-block;
    vertical-align: top;
  }

  // Modifiers
  &.is-mobile {
    display: flex;

    .level-left,
    .level-right {
      display: flex;
    }

    .level-left + .level-right {
      margin-top: 0;
    }

    .level-item {
      &:not(:last-child) {
        margin-bottom: 0;

        @include ltr-property("margin", $level-item-spacing);
      }

      &:not(.is-narrow) {
        flex-grow: 1;
      }
    }
  }

  // Responsiveness
  @include tablet {
    display: flex;

    & > .level-item {
      &:not(.is-narrow) {
        flex-grow: 1;
      }
    }
  }
}

.level-item {
  align-items: center;
  display: flex;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;

  .title,
  .subtitle {
    margin-bottom: 0;
  }

  // Responsiveness
  @include mobile {
    &:not(:last-child) {
      margin-bottom: $level-item-spacing;
    }
  }
}

.level-left,
.level-right {
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;

  .level-item {
    // Modifiers
    &.is-flexible {
      flex-grow: 1;
    }

    // Responsiveness
    @include tablet {
      &:not(:last-child) {
        @include ltr-property("margin", $level-item-spacing);
      }
    }
  }
}

.level-left {
  align-items: center;
  justify-content: flex-start;

  // Responsiveness
  @include mobile {
    & + .level-right {
      margin-top: 1.5rem;
    }
  }


  @include tablet {
    display: flex;
  }
}

.level-right {
  align-items: center;
  justify-content: flex-end;

  // Responsiveness
  @include tablet {
    display: flex;
  }
}
