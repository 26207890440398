@import "mixins";

%control {
  @include control;
}

%unselectable {
  @include unselectable;
}

%arrow {
  @include arrow;
}

%block {
  @include block;
}

%delete {
  @include delete;
}

%loader {
  @include loader;
}

%overlay {
  @include overlay;
}

%reset {
  @include reset;
}
