// @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lily+Script+One&display=swap");

$primary: #c93036;
// $info: #1A151A !default;
// $success: $primary !default;
// $warning: #ec7d10 !default;
// $danger: #ce2d4f !default;

// $light: $white-ter !default;
// $dark: $grey-darker !default;

$color1: #6f3047;
$color2: #e14a43;
$color3: #e5c99b;
$bgColor: #FAF9F6;
$color4: rgba(236, 125, 16, 0.4);
$color5: lighten(#ec7d10, 15%);

$color2-light: lighten($color2, 35%);
$color1-light: lighten($color1, 35%);
// Invert colors

// $orange-invert   : findColorInvert($orange) !default;
// $yellow-invert   : findColorInvert($yellow) !default;
// $green-invert    : findColorInvert($green) !default;
// $turquoise-invert: findColorInvert($turquoise) !default;
// $cyan-invert     : findColorInvert($cyan) !default;
// $blue-invert     : findColorInvert($blue) !default;
// $purple-invert   : findColorInvert($purple) !default;
// $red-invert      : findColorInvert($red) !default;

// $primary-invert  : findColorInvert($primary) !default;
// $primary-light   : findLightColor($primary) !default;
// $primary-dark    : findDarkColor($primary) !default;
// $info-invert     : findColorInvert($info) !default;
// $info-light      : findLightColor($info) !default;
// $info-dark       : findDarkColor($info) !default;
// $success-invert  : findColorInvert($success) !default;
// $success-light   : findLightColor($success) !default;
// $success-dark    : findDarkColor($success) !default;
// $warning-invert  : findColorInvert($warning) !default;
// $warning-light   : findLightColor($warning) !default;
// $warning-dark    : findDarkColor($warning) !default;
// $danger-invert   : findColorInvert($danger) !default;
// $danger-light    : findLightColor($danger) !default;
// $danger-dark     : findDarkColor($danger) !default;
// $light-invert    : findColorInvert($light) !default;
// $dark-invert     : findColorInvert($dark) !default;

// General colors

// $scheme-main       : $white !default;
// $scheme-main-bis   : $white-bis !default;
// $scheme-main-ter   : $white-ter !default;
// $scheme-invert     : $black !default;
// $scheme-invert-bis : $black-bis !default;
// $scheme-invert-ter : $black-ter !default;

// $background        : $white-ter !default;

// $border            : $grey-lighter !default;
// $border-hover      : $grey-light !default;
// $border-light      : $grey-lightest !default;
// $border-light-hover: $grey-light !default;

// Text colors

$text: #6f3047;
// $text-invert: findColorInvert($text) !default;
// $text-light : $grey !default;
// $text-strong: $grey-darker !default;

// Code colors

// $code           : $red !default;
// $code-background: $background !default;

// $pre            : $text !default;
// $pre-background : $background !default;

// Link colors

$link: $text;
$link-visited: $text !important;
// $link-invert       : findColorInvert($link) !default;
// $link-light        : findLightColor($link) !default;
// $link-dark         : findDarkColor($link) !default;

$link-hover: $primary;
// $link-hover-border : $grey-light !default;

// $link-focus        : $grey-darker !default;
// $link-focus-border : $blue !default;

// $link-active       : $grey-darker !default;
// $link-active-border: $grey-dark !default;

// Typography

$family-primary: "Nunito", sans-serif;
// $family-secondary: $family-sans-serif !default;
// $family-code     : $family-monospace !default;

// $size-small : $size-7 !default;
// $size-normal: $size-6 !default;
// $size-medium: $size-5 !default;
// $size-large : $size-4 !default;

// Lists and maps
// $custom-colors: null !default;
// $custom-shades: null !default;
