@import "../utilities/mixins";

$container-offset: 2 * $gap !default;
$container-max-width: $fullhd !default;

.container {
  flex-grow: 1;
  margin: 0 auto;
  position: relative;
  width: auto;

  &.is-fluid {
    max-width: none !important;
    padding-left: $gap;
    padding-right: $gap;
    width: 100%;
  }

  @include desktop {
    max-width: $desktop - $container-offset;
  }


  @include until-widescreen {
    &.is-widescreen:not(.is-max-desktop) {
      max-width: min($widescreen, $container-max-width) - $container-offset;
    }
  }


  @include until-fullhd {
    &.is-fullhd:not(.is-max-desktop):not(.is-max-widescreen) {
      max-width: min($fullhd, $container-max-width) - $container-offset;
    }
  }


  @include widescreen {
    &:not(.is-max-desktop) {
      max-width: min($widescreen, $container-max-width) - $container-offset;
    }
  }


  @include fullhd {
    &:not(.is-max-desktop):not(.is-max-widescreen) {
      max-width: min($fullhd, $container-max-width) - $container-offset;
    }
  }
}
