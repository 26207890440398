.blog {
  &_page-header {
    margin: 1.2rem 1.2rem 1.2rem 0;
    font-weight: bold;
    font-size: 1.5rem;
  }
  &_title {
    display: flex;
    padding: 0.5rem 0;
    font-weight: bold;
   
  &-box {
    display: flex;
    justify-content: center;
    align-items: center;
    }  
  &-image {
    width: 30px;
    height: 30px;
    padding: 0.5rem;
    vertical-align: middle;
    }
  &-text {
    vertical-align: middle;
    font-size: 1.3rem;
    font-weight: 600;
    }  
  &-date {
    align-self: flex-end;
  }    
  }
}

.post {
  &_title {
    font-size: 2.5rem;
    font-weight: bold;
    margin: 0.5rem 0;
  }

  &_contain {   
    font-size: 1.4rem;
    line-height: 1.5;
    font-weight: 400;
    letter-spacing: 0.5px;
  }
}

h2 {
  font-size: 1.953rem;
  font-weight: bold;
  margin: 1rem 0;
}

h3 {
  font-size: 1.563rem;
  font-weight: bold;
  margin: 1rem 0;
}

h4 {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 1rem 0;
}

h5 {
  font-size: 1rem;
  font-weight: bold;
  margin: 1rem 0;
}

h6 {
  font-size: 0.8rem;
  font-weight: bold;
  margin: 1rem 0;
}

p {
  font-size: 1.4rem;
  line-height: 1.5;
  font-weight: 400;
  letter-spacing: 0.5px;
  margin: 1rem 0;
}
