@import "../utilities/mixins";

.is-clearfix {
  @include clearfix;
}

.is-pulled-left {
  float: left !important;
}

.is-pulled-right {
  float: right !important;
}
