.work {
  padding: 0.8em 0em;

  &__pageTitle {
    font-size: 2.2rem;
    line-height: 60px;
    font-weight: bold;
    padding: 0.8em 0em;
  }

  &__title {
    font-size: 22px;
    line-height: 60px;
    padding: 0em 0em 0.2em 0em;
    font-weight: 500;
  }

  &__left {
    padding-left: 2em;
  }

  &__contents {
    align-items: flex-start;
  }

  &__projectName {
    font-size: 1.5rem;
    line-height: 1em;
    padding: 0.2em 0em 0.5em 0em;
    font-weight: 700;
  }

  &__date {
    font-weight: 500;
    font-size: 18px;
    line-height: 60px;
    background: #142850;
    border-radius: 16px;
    color: #fff;
    padding: 0.5em 0.8em;
    margin-right: 1.2em;
  }

  &__tags {
    font-size: 1.4rem;
    line-height: 29px;
    color: #8695a4;
  }

  &__description {
    font-size: 1.1rem;
    line-height: 23px;
    padding: 0em 0em 0.3em 0em;
  }
}

.project_image {
  border-radius: 5px;
}

.project_title {
  cursor: pointer;
}

@media (max-width: $tablet) {
  .work {
    &__title {
      text-align: center;
    }
    &__left {
      padding-left: 0em;
    }
  }
}

.project {
  padding: 10px;
  padding-left: 20px;
  max-width: 635px;
  width: 100%;
  position: relative;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;

  &:hover {
    background-color: $color2-light;
    border-radius: 10px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;

    &::before {
      content: "";
      background-color: $color1-light;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 10px;
      height: 100%;
      z-index: 1;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: center;
  }

  &__title {
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    font-weight: 700;
    position: relative;
    padding-left: 1.6em;

    &__icon {
      position: absolute;
      top: 11;
      left: 0;
      width: 1.3em;
      height: 1.3em;
    }
  }

  &__body {
  }
}
