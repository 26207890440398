.home {
  display: flex;
  flex-direction: column;
  height: 60vh;
  align-items: center;
  justify-content: center;
  text-align: center;

  &__title {
    padding-top: 10px;
    font-weight: bold;
    font-size: 32px;
  }

  &__subtitle {
    display: inline-block;
    width: 400px;
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 23.5px;
    padding: 1.3em 1.5em;
  }

  &__image {
    height: 243px;
    width: 243px;
    border-radius: 50%;
    text-align: center;
  }
}

// .home_header {
//   display: inline-block;
//   justify-content: center;
//   align-items: center;
//   // padding: 5.3em 0.8em;
//   // margin: 1.5em 1em;

// @media (max-width: $tablet) {
//   .home_header {
//     display: flex;
//     flex-direction: column-reverse;
//     padding: 0em 1.2em;
//   }

//   .recent-post {
//     &__heading {
//       text-align: center;
//     }
//   }
// }

// .recent-post {
//   background-color: #edf7fa;
//   padding: 2.3em 0.8em;
//   padding-bottom: 4.3em;

//   &__heading {
//     font-size: 22px;
//     line-height: 60px;
//     font-weight: 700;
//   }
// }
