/** 1 helpers **/
@import "./1-helpers/initial-variables";
@import "./1-helpers/generic";
@import "./1-helpers/variables";
@import "./1-helpers/functions";
@import "./1-helpers/mixins";

/** 2 Tools **/
@import "./2-tools/bulma";
@import "./2-tools/highlight";
// @import "./2-tools/typicons";

/** 3. basics **/
@import "./3-basics/base";
@import "./3-basics/buttons";
@import "./3-basics/links";
@import "./3-basics/reset";
@import "./3-basics/typography";

/** 4.layouts **/
@import "./4-layouts/navbar";
@import "./4-layouts/footer";

/**5.modules **/
@import "./5-modules/timeline";
@import "./5-modules/skills";

/** pages **/
@import "./6-pages/home";
@import "./6-pages/blog";
@import "./6-pages/work";
@import "./6-pages/about";

// .highlight {
//   border-radius: 5px;
//   background-color: #f6f6f6 !important;
// }
