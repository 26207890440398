.navbar-item {
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;

  &:hover {
    color: $primary !important;
  }
}

.navbar-brand-text {
  font-family: "Lily Script One", cursive;
  color: $text;
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 2px;

  &:hover {
    color: $primary;
  }
}

@media (max-width: 1024px) {
  .navbar-menu {
    box-shadow: none;
    margin-bottom: 0.8em;
  }
  .animate-in {
    animation: slideIn 0.2s ease-in;
  }

  .animate-out {
    animation: slideOut 0.2s ease-in;
  }

  .navbar-item {
    animation: showItems 0.6s ease-in-out;
  }

  .navbar-item-remove {
    animation: removeItems 0.2s ease-in-out;
  }

  @keyframes slideIn {
    from {
      height: 0px;
    }
    to {
      height: 100px;
    }
  }

  @keyframes slideOut {
    from {
      height: 100px;
    }
    to {
      height: 0px;
    }
  }

  @keyframes showItems {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes removeItems {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }
}
